import { ReactComponent as PlexIcon } from './plexIcon.svg';
import styles from "./plexrefreshbutton.module.css";

const PlexRefreshButton = ({server,port,library,token}) => {
    const refresh = () => {
        fetch("https://plex.tv/api/resources?includeHttps=1&X-Plex-Token="+token)
        .then((response) => {
            if(response.ok) {
                //alert("Refresh of plex library successfully launched");
                return response.text();
            }

            alert("Fail to refresh plex library");
        })
        .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
        .then(data => {
            const device = data.getElementsByName(server)[0];
            const connections = device.getElementsByTagName("Connection");
            const url = connections[connections.length - 1].getAttribute('uri');
            fetch(url+"/library/sections/"+library+"/refresh/?X-Plex-Token="+token)
            .then((response) => {
                alert(response.ok ? "Refresh of plex library successfully launched" : "Fail to refresh plex library")
            })
        });
    }
    const title = 'Refresh Plex'

    return <button className={styles.plexButton + " btn"} onClick={refresh} title={title} type='button'><PlexIcon /></button>
}

export default PlexRefreshButton