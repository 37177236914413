import { useContext } from "react";
import { BrowserContext } from "../services/BrowserContext";
import ContentActionMenu from "../ContentActionMenu";
import ItemName from "../ItemName";

const File = ({name,path,size,children,onDoubleClick,rename}) => {
    const {itemsManipulator} = useContext(BrowserContext);

    const download = function() {
        itemsManipulator.name = name;
        itemsManipulator.path = path;
        itemsManipulator.download();
    }
   
    const sizeInKo = Math.ceil(size/1024).toLocaleString();
    return (
        <button className="list-group-item list-group-item-action justify-content-between d-flex align-items-center" onDoubleClick={onDoubleClick ?? download}>
            {children}
            <div className="row col align-items-center">
                <div className="col-md-8 col-lg-9 col-xl-10 text-break">
                    <div className="d-flex align-items-center"><ItemName name={name} path={path} rename={rename}></ItemName></div>
                </div>
                <div className="col-md-4 col-lg-3 col-xl-2 ">
                    {sizeInKo} ko
                </div>
            </div>
            <div className="col-auto d-flex justify-content-end">
                <ContentActionMenu name={name} path={path}/>
            </div>
        </button>
    )
}

File.Ico = ({children}) => <div className="col-auto">{children}</div>

export default File