import { Dropdown } from "react-bootstrap";

const SortButton = ({setSortType,setSortOrder,sortOrder}) => {

    const sort = () => {
        sortOrder === "asc" ? setSortOrder("desc") : setSortOrder("asc");
    }

    const setTypeName = () => {
        setSortType("name");
    }

    const setTypeSize = () => {
        setSortType("size");
    }
    
    return (
        <>
        <span className="btn p-0 ms-1" onClick={sort} title="Sort"><i className={sortOrder === "asc" ? "bi bi-arrow-up" : "bi bi-arrow-down"}></i></span>
        <Dropdown title="Sort by" className="btn p-0 ms-1">
          <Dropdown.Toggle as="i" className="bi bi-funnel" type="button">
          </Dropdown.Toggle>
    
          <Dropdown.Menu>
            <Dropdown.Item as="div" onClick={setTypeName}>Name</Dropdown.Item>
            <Dropdown.Item as="div" onClick={setTypeSize}>Size</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        </>
    )
}
export default SortButton