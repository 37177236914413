import { useContext } from "react";
import { BrowserContext } from "../../../services/BrowserContext";
import styles from "./pastebutton.module.css";

const PasteButton = ({filePath,currentPath,action}) => {
    const {itemsManipulator,handleClipboard} = useContext(BrowserContext);

    const paste = () => {
        itemsManipulator.name = filePath.pop();
        itemsManipulator.path = filePath;
        itemsManipulator.paste(currentPath,action);
        handleClipboard(false,false);
    }

    const title = 'Paste'

    return filePath ? <button className={styles.PasteButton + " btn btn-info"} onClick={paste} title={title}><i className="bi bi-clipboard"></i></button> :  ""
    
}
export default PasteButton