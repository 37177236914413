import {useRef} from "react";
import styles from "./uploadbutton.module.css";

const UploadButton = (props) => {
    let fileInput = useRef(null);

    function addFile(e) {
        e.preventDefault();
        fileInput.current.click();
    }
    const title = 'Upload File'

    return (
        <form onSubmit={e => addFile(e)}>
            <button id="addFile" type="submit" className={styles.UploadButton + " btn btn-secondary"} title={title}><i className="bi bi-file-earmark-arrow-up"></i></button>
            <input className="file-input" type="file" name="file" onChange={e => props.uploader.addUploadFiles(e.target.files,props.path)} ref={fileInput} multiple hidden/>
        </form>
    )
}

export default UploadButton