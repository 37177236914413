import { useContext, useState } from "react";
import { createPortal } from 'react-dom';
import File from "./File";
import { Modal } from "react-bootstrap";
import { BrowserContext } from "../services/BrowserContext";

import { Worker,Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const Pdf = (props)=>{
    const {itemsManipulator} = useContext(BrowserContext);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    return (
        <>
        <File onDoubleClick={handleShow} {...props}><File.Ico><i className="bi bi-file-earmark-pdf fs-4 me-1"></i></File.Ico></File>
        {createPortal(
            <Modal centered show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>{props.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}></Worker>
                <Viewer fileUrl={process.env.REACT_APP_API_URL+"?session_id="+itemsManipulator.session_id+"&action=download&path="+encodeURI(props.path.join('/'))+encodeURI('/')+encodeURI(props.name)} />
                </Modal.Body>
                
            </Modal>,
            document.body
        )}
        </>
    );
        
}
export default Pdf