import { useEffect, useState } from "react";

const Search = ({elements,path,handleCurrentFolder,limit,handleSearchLimit}) => {

    const results = {'name' : 'Results', 'type' : 'folder', 'child' :[]};
    let currentFolder = elements;
    path.forEach(folderName => currentFolder = currentFolder.child.find( element => element.name === folderName && element.type === 'folder'));

    function find(path,folder,name){
        let finds = folder.child.filter((element)=>element.name.toLowerCase().includes(name.toLowerCase()) );
        finds.forEach((element) => element.path = Array.from(path));
        handleResults(finds);
        folder.child.forEach((element) => {
            if(element.type === 'folder'){
                const newPath = Array.from(path);
                newPath.push(element.name);
                find(newPath,element,name);
            }
        });
    }

    const handleResults = (newResults)=>{
        results.child.push(...newResults);
        results.child = results.child.slice(0, limit);
        handleCurrentFolder({...results},true);
    }
    const [name,setName] = useState('') ;
    const handleName = (e) => {
        setName(e.target.value);
        handleSearchLimit(50,false);
    }

    useEffect(() => {
        setName("");
    },[path]);

    useEffect(() => {
        if(name !== ""){
            find(path,currentFolder,name);
        }else{
            handleCurrentFolder(currentFolder);
        }
        // eslint-disable-next-line
    },[limit,name]);

    return (
        <div className="flex-grow-1">
            <input placeholder="Search" style={{maxWidth : "250px"}} className="form-control float-end" value={name} onChange={handleName}></input>
        </div>
        
    )
}

export default Search