import { useState } from "react";
import { createPortal } from 'react-dom';
import File from "./File";
import { Modal } from "react-bootstrap";


const Video = (props)=>{
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    return (
        <>
        <File onDoubleClick={handleShow} {...props}><File.Ico><i className="bi bi-film fs-4 me-1"></i></File.Ico></File>
        {createPortal(
            <Modal centered show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{props.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <video className="w-100" controls>
                        <source src={process.env.REACT_APP_API_URL+"?action=download&path="+encodeURI(props.path.join('/'))+encodeURI('/')+encodeURI(props.name)} type="video/webm"/>
                        Your browser does not support the video tag.
                    </video>
                </Modal.Body>
                
            </Modal>,
            document.body
        )}
        </>
    );
        
}
export default Video