import React, { useState,useContext } from "react";
import { createPortal } from 'react-dom';
import File from "./File";
import { Modal } from "react-bootstrap";
import { BrowserContext } from "../services/BrowserContext";

const Text = (props)=>{

    const {itemsManipulator} = useContext(BrowserContext);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        fetch(process.env.REACT_APP_API_URL+"?action=download&path="+encodeURI(props.path.join('/'))+encodeURI('/')+encodeURI(props.name)+"&session_id="+itemsManipulator.session_id)
            .then(response => response.text())
            .then(data => {
                document.getElementById('fileContent').textContent = data;
            })
            .catch(error => {
                console.error('Erreur:', error);
            });
        
    }
    
    return (
        <>
        <File onDoubleClick={handleShow} {...props}><File.Ico><i className="bi bi-file-earmark-text fs-4 me-1 text-dark"></i></File.Ico></File>
        {createPortal(
            <Modal centered show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{props.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <pre id="fileContent"></pre>
                </Modal.Body>
                
            </Modal>,
            document.body
        )}
        </>
    );
        
}
export default Text