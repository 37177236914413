import { useContext } from "react";
import File from "./File";
import { BrowserContext } from "../services/BrowserContext";

const Image = (props)=>{
    const {handleCarouselActiveFileName} = useContext(BrowserContext);

    const displayCarousel = function() {
        handleCarouselActiveFileName(props.name);
    }
    
    return <File onDoubleClick={displayCarousel} {...props}><File.Ico><i className="bi bi-file-earmark-image fs-4 me-1"></i></File.Ico></File>
}

export default Image