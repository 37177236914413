import styles from "./newfolderbutton.module.css";

const NewFolderButton = ({handleNewFolder}) => {
    const createFolder = () => {
        handleNewFolder(true);
        document.getElementById('content-items').scrollTo(0,0);
    }

    const title = 'New Folder'
    
    return <button className={styles.FolderButton + " btn btn-warning"} onClick={createFolder} title={title}><i className="bi bi-folder-plus"></i></button>
        
}

export default NewFolderButton