import { useContext } from "react";
import { BrowserContext } from "./services/BrowserContext";
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';

const Profile = ({user,handleUser})=>{
    const {itemsManipulator} = useContext(BrowserContext);

    const logout = function (e) {
        itemsManipulator.logout();
        handleUser('');
    }

    const displayLoginModal = () => itemsManipulator.displayLoginModal(true);

    return user 
    ? (
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          <span className="text-capitalize">{user.name}</span>
        </Dropdown.Toggle>
  
        <Dropdown.Menu>
          <Dropdown.Item as="button" onClick={logout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
    :(
      <Button variant="secondary" onClick={displayLoginModal}>login</Button>
    )
}

export default Profile